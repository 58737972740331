<template>
    <div 
        v-if="showNotes && notes"
        class="flex flex-col content-start h-full w-full bg-yellow-100">
        
        <div class="flex-none flex items-center p-2 md:p-4">
            <button 
                class="button transparent mr-2"
                @click="$emit('close')">
                 
                <i class="fas fa-times text-black" />
            </button>
            
            <h2 class="text-black m-0 flex-grow">{{$t('notes.notes')}}</h2>
        </div>
        
        <div class="flex-grow h-full w-full overflow-hidden flex flex-col">
            <div class="flex-none p-4 pt-0 md:p-8 md:pt-0">
                <div class="text-black p-4 rounded-lg bg-white border border-yellow-300">
                    <TextareaAutosize 
                        :placeholder="$t('notes.writeANote')"
                        v-model="noteNew.content" 
                        spellcheck="false"
                        style="background: none; padding: 0; font-size: 1rem; border: 0; outline: 0; color: #000; width: 100%;"
                    />
                    
                    <div 
                        v-if="noteNew.content"
                        class="mt-6 buttons-wrapper">
                        
                        <button 
                            type="submit"
                            class="button note"
                            :class="{'loading' : loading == 'create'}"
                            @click="createNote()">
                            
                            {{ $t('general.save') }}
                        </button>
                    </div>
                </div>
            </div>
            
            <div 
                v-if="notes.length == 0"
                class="flex-grow h-full w-full flex items-center justify-center flex-col  ">
                    
                    <div class="rounded-full w-64 h-64 flex flex-col items-center justify-center bg-white text-gray-500">
                        <i class="far fa-sticky-note text-yellow-400 text-6xl mb-4" />
                        {{ $t('notes.noNotesYet') }}
                    </div>
            </div>
            <div 
                v-else
                class="flex-grow overflow-x-hidden overflow-y-auto px-4 md:px-8">
                
                <Note 
                    v-for="note in notes"
                    :key="note.id"
                    :note="note"
                />
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    components: {
        'Note': () => import('@/components/notes/Note.vue'),
    },
    
    computed: {
        notes(){
            const notes = this.$store.getters.getNotes;
            if ( notes ) {
                return notes.filter( n => !n.deletedAt );
            }
            return null
        },
    },
    
    data() {
        return {
            loading: null,
            // noteEdit: null,
            showNotes: false,
            noteNew: {
                content: null,
            }
        }
    },
    
    
    methods: {
        closeHandler( e ){
            if (e.keyCode === 27) {
                this.$emit('close');
            }
        },
        
        async createNote(){
            try {
                this.loading = 'create';
                
                if ( !this.noteNew.content ) {
                    throw Error( this.$t('notes.needContent') )
                }
                
                const note = await this.$store.dispatch('createNote', this.noteNew);
                this.$notify({ type: 'success', text: this.$t('notes.savedNote') });
                this.noteNew.content = null;
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    // title: 'Error', 
                    text: e.message || this.$t('notes.couldNotSaveNote')
                });
                
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
        
        cancelNewNote(){
            
        }
    },
    
    async mounted(){
        this.showNotes = true;
        window.addEventListener('keyup', this.closeHandler );
    },
    
    beforeDestroy() {
        window.removeEventListener('keyup', this.closeHandler);
    },
}
</script>

<style lang="scss">
    
</style>
